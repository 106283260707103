import { Children, cloneElement, useEffect, useState } from 'react';

import { useUpdateDepartmentQueryById } from '../../../Common/Queries/departmentQueries';
import { useUpdateLocationQueryById } from '../../../Common/Queries/locationQueries';

/**
 * @function formateData
 * @param values
 * @returns formated values
 */
const formateData = (values) => values;

export function EditLocationContainer(props) {
  const { children, formData, setOpen, title } = props;
  const child = Children.only(children);
  const putToLocation = useUpdateLocationQueryById();
  const putToDepartment = useUpdateDepartmentQueryById();
  const [values, setValues] = useState(formData);

  /**
   * this function is utility function for submit data get by user
   * @param e default parameter
   * @param body location parameter
   */
  function handleOnSubmit(body) {
    const reqBody = formateData(body);
    const reqParams = { reqBody, reqId: formData.id };
    if (title === 'Location') putToLocation.mutate(reqParams);
    else putToDepartment.mutate(reqParams);
  }

  useEffect(() => {
    if (putToLocation.isSuccess || putToDepartment.isSuccess) setOpen(false);
  }, [putToLocation.isSuccess, putToDepartment.isSuccess]);

  useEffect(() => {
    setValues(formData);
  }, [formData]);

  return cloneElement(child, {
    title,
    putToLocation,
    putToDepartment,
    handleOnSubmit,
    values: values || {},
  });
}
