// @ts-check

import { faro } from '@grafana/faro-web-sdk';
import jwtDecode from 'jwt-decode';
import { marked } from 'marked';
import TurndownService from 'turndown';

import { LOGGED_IN_USER_INFO } from '../constants';

import { getParsedValueFromLocalStorage } from '../services/localStorage';

/**
 * @function isLogin
 * check whether local storage has access and refresh token or not
 * @returns Boolean
 */
export const isLogin = () => {
  const userInfo = getParsedValueFromLocalStorage(LOGGED_IN_USER_INFO);
  return !!(userInfo && userInfo.accessToken && userInfo.refreshToken);
};

export const generateSuffix = (day) => {
  const suffixes = ['th', 'st', 'nd', 'rd'];
  const v = day % 100;
  return suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0];
};

/**
 * @function decodeJwt
 * @param {String} jwtToken
 * convert the jwt token
 * @returns Object
 */
export const decodeJwt = (jwtToken) => {
  try {
    const decodedInformation = jwtDecode(jwtToken);
    return decodedInformation;
  } catch (error) {
    return null;
  }
};

/**
 * @function epochToTimeSince
 * @param {Number} milliseconds
 * @returns time  string in required format
 */
export const epochToTimeSince = (milliseconds) => {
  const date = new Date(Number(milliseconds));
  let hours = date.getHours();
  let minutes = `${date.getMinutes()}`;
  const amPm = hours >= 12 ? ' PM' : ' AM';
  hours %= 12;
  hours = hours || 12; // the hour '0' should be '12'
  minutes = Number(minutes) < 10 ? `0${minutes}` : `${minutes}`;
  const strTime = `${hours}:${minutes}${amPm}`;
  if (`${date.toLocaleDateString()}` !== `${new Date().toLocaleDateString()}`) {
    return `${date.toLocaleDateString()} ${strTime}`;
  }
  return `Today ${strTime}`;
};

/**
 * @function formattedDate
 * @param {Date} date
 * @returns date string in required format
 */

export const formattedDate = (date, isSuffix = false) => {
  const regex = /^0[1-9]$/;
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const currentDateTime = new Date(date);
  let day = `0${currentDateTime.getDate()}`.slice(-2);
  if (isSuffix && regex.test(day)) {
    const [_, valuex] = day;
    day = valuex;
  }
  const suffixValue = generateSuffix(day);
  const formattedDateValue = `${day}${isSuffix ? suffixValue : ''}-${
    months[currentDateTime.getMonth()]
  }-${currentDateTime.getFullYear()}`;
  return formattedDateValue;
};

/**
 * @function debounce
 * @param {Function} func
 * @returns
 */

export const debounce = (func, timeout = 300) => {
  let timer;
  return (/** @type {any} */ ...args) => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      func.apply(this, args);
    }, timeout);
  };
};
/**
 * @function componentLazyLoader
 * @param {Function} lazyComponent
 * @param {Number} attemptsLeft
 * @returns Promise
 */

export const componentLazyLoader = (lazyComponent, attemptsLeft) => {
  return new Promise((resolve, reject) => {
    // check if the window has already been refreshed
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem('retry-lazy-refreshed') || 'false'
    );
    lazyComponent()
      .then((/** @type {any} */ component) => {
        window.sessionStorage.setItem('retry-lazy-refreshed', 'false');
        resolve(component);
      })
      .catch((/** @type {any} */ error) => {
        if (faro && faro.api && faro.api.pushError) {
          faro.api.pushError(error);
        }
        if (!hasRefreshed && attemptsLeft === 1) {
          // not been refreshed yet
          window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
          return window.location.reload(); // refresh the page
        }
        // let us retry after 1500 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          componentLazyLoader(lazyComponent, attemptsLeft - 1).then(resolve, reject);
        }, 1500);
        return null;
      });
  });
};

/**
 *
 * @param {String} content
 * @returns HTML content
 */
export const markdownToHTML = (content = '') => {
  marked.setOptions({ baseUrl: 'https://' });
  return marked.parse(content);
};

/**
 *
 * @param {String} content HTML content
 * @returns Markdown of content
 */
export const htmlToMD = (content = '') => {
  const turndownService = new TurndownService({ headingStyle: 'atx', bulletListMarker: '-' });
  return turndownService.turndown(content);
};

/**
 * @function Nbsp
 * @returns non breaking space
 */

export const Nbsp = () => '\u00A0';

/**
 * @function IntlFormatedDate
 * @param {Date} date
 * @returns date string in required format
 */

export const IntlFormatedDate = (date) =>
  Intl.DateTimeFormat('en', { year: 'numeric', month: 'short', day: '2-digit' }).format(
    new Date(date)
  );

export const replaceDate = (utcTime, message) => {
  const localDate = epochToTimeSince(utcTime)?.split(' ')?.slice(1, 3)?.join(' ');
  const readable = formattedDate(utcTime, true).split('-').join(' ');
  const finalDateTime = `${localDate},${Nbsp()}${readable}`;
  const dateTimeRegex =
    /\b\d{1,2}(?::\d{2})?\s?[APap][Mm]?\s?(?:[A-Za-z]{2,4})?,?\s?\d{1,2}(?:st|nd|rd|th)?\s\w+\s\d{4}\b/;

  const updatedMessage = message.replace(dateTimeRegex, finalDateTime);
  return updatedMessage;
};
