import { createContext } from 'react';

/**
 * @description base URL for mock server
 */
export const BASE_URL = window.env?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL;

/**
 * @description End point for Login
 */
export const LOGIN_ENDPOINT = '/login';

/**
 * @description End point for Filters
 */
export const FILTERS_ENDPOINT = '/filters';

/**
 * @description End point for Levels
 */
export const LEVELS_ENDPOINT = '/levels';

/**
 * @description End point for Skills
 */
export const SKILLS_ENDPOINT = '/skills';

/**
 * @description End point for HR Reports
 */
export const HR_REPORTS_ENDPOINT = '/reports/hr';

/**
 * @description End point for Agencies
 */
export const PANEL_REPORTS_ENDPOINT = '/reports/panel';

/**
 * @description End point for Departments
 */
export const DEPARTMENTS_ENDPOINT = '/departments';

/**
 * @description End point for Reports
 */
export const REPORTS_ENDPOINT = '/reports';

/**
 * @description End point for Dashboard
 */
export const DASHBOARD_ENDPOINT = '/dashboard';

/**
 * @description End point for CANDIDATE
 */
export const CANDIDATE_ENDPOINT = '/candidate';
/**
 * @description End point for CANDIDATES
 */
export const CANDIDATES_ENDPOINT = '/candidates';

/**
 * @description End point for Positions
 */
export const POSITIONS_ENDPOINT = '/positions';

/**
 * @description End point for Teams
 */
export const TEAMS_ENDPOINT = '/teams';

/**
 * @description End point for Streams
 */
export const STREAMS_ENDPOINT = '/streams';

/**
 * @description End point for Tasks
 */
export const TASKS_ENDPOINT = '/tasks';

/**
 * @description End point for Campus
 */
export const CAMPUS_ENDPOINT = '/campus';

/**
 * @description End point for calendar
 */
export const CALENDAR_EVENTS_ENDPOINT = '/calendarEvents';

/**
 * @description End point for user login
 */
export const USER_LOGIN_ENDPOINT = '/login';

/**
 * @description End point for user logout
 */
export const USER_LOGOUT_ENDPOINT = '/logout';

/**
 * @description End point for user access token
 */
export const ACCESS_TOKEN_ENDPOINT = '/refresh';

/**
 * @description End point for Employees
 */
export const EMPLOYEE_ENDPOINT = '/employees';

/**
 * @description End point for Applications
 */
export const APPLICATIONS_ENDPOINT = '/applications';

/**
 * @description End point for Applications new view
 */
export const APPLICATIONS_BETA_ENDPOINT = '/applications-beta';

/**
 * @description End point for Referrals
 */
export const REFERRALS_ENDPOINT = '/referrals';

/**
 * @description End point for My Open Position
 */
export const MY_OPEN_POSITIONS_ENDPOINT = '/my-open-positions';

/**
 * @description End point for settings
 */
export const SETTINGS_ENDPOINT = '/settings';

/**
 * @description End point for master invite template
 */
export const INVITE_TEMPLATE_ENDPOINT = '/invite-templates';

/**
 * @description End point for locations
 */
export const LOCATIONS_ENDPOINT = '/locations';

/**
 * @description End point for config
 */
export const CONFIG_ENDPOINT = '/configs';

/**
 * @description End point for Application details board
 */
export const APPLICATIONS_DETAILS_ENDPOINT = '/applications-details';

/**
 * @description End point for Application Reports
 */
export const APPLICATION_OVERVIEW_ENDPOINT = '/application-overview';

/**
 * @description End point for Interviews
 */
export const INTERVIEWS_ENDPOINT = '/interviews';
export const PREVIOUS_INTERVIEWS_ENDPOINT = '/previous-interviews';

/**
 * @description End point for Interviews
 */
export const CALENDAR_ENDPOINT = '/calendar';
export const TABULAR_EVENTS_ENDPOINT = '/calendar/table';

/**
 * @description End point for Users
 */
export const USERS_ENDPOINT = '/users';

/**
 * @description End point for Events
 */
export const EVENTS_ENDPOINT = '/events';

/**
 * @description End point for Resume Screening
 */
export const RESUME_SCREENING = '/resume-screening';

/**
 * @description End point for Pre Screening
 */
export const PRE_SCREENING = '/pre-screening';

/**
 * @description End point for Campus Hiring
 */
export const CAMPUS_HIRING = '/campus-hiring';

/**
 * @description End point for the Team Requirements
 */

export const TEAM_REQUIREMENTS_END_POINT = '/requirements';

/**
 * @description retry limit for component import
 */
export const ALLOWED_RETRY_LIMIT = 5;

/**
 * @description End point for positing Resume
 */
export const RESUME_POST_ENDPOINT = 'https://www.googleapis.com/upload/drive/v3/files';

/**
 * @description End point for CAPTCHA_TOKEN
 */
export const CAPTCHA_ENDPOINT = '/driveToken';

/**
 * @description End point for Agencies
 */
export const AGENCIES_ENDPOINT = '/agencies';

/**
 * @description End point for application duplicate check
 */
export const DUPLICATE_CHECK_ENDPOINT = '/applicants/check-duplicate';
/**
 * @description End point for applicant details page
 */
export const APPLICANT_DETAILS_ENDPOINT = '/applicants-details';

/**
 * @description End point for applicant page
 */
export const APPLICANT_ENDPOINT = '/applicant';
/**
 * @description End point for File Upload URL
 */
export const FILE_UPLOAD = '/file-upload-url';

/**
 * @description End point for service configurations auth tokens
 */
export const SERVICES_AUTH_TOKEN_ENDPOINT = '/generate-auth-token';

/**
 * @description End point for service configurations refresh tokens
 */
export const SERVICE_CONFIGURATIONS_REFRESH_ENDPOINT = '/refresh-token';

/**
 * @description constants for displaying error
 */
export const LENGTH_ERROR = '* text limit exceed';
export const EMPTY_ERROR = '* must be non empty';
export const TIME_ERROR = '* must be greater then current time';
export const MESSAGE_ERROR = '* must contain some message';
export const EMAIL_ERROR = '* must be of type email';
export const PHONE_NUMBER_ERROR = '* invalid number';
export const NOT_NEGATIVE = '* must be positive';
export const CHARACTER_LIMIT_EXCEEDS = 'max character limit exceeds';
export const LINK_ERROR = '* must be of type link';
export const GITHUB_LINK_ERROR = '* link must be of type https://github.com/...';
export const LINKEDIN_LINK_ERROR = '* link must be of type https://www.linkedin.com/...';
export const SELECT_ERROR = '* select field';
export const UPSTREAM_INVALID_TOKEN_MESSAGE = 'The access token is invalid or has expired';
export const INTERVIEW_CONFLICT_MESSAGE = 'Interviewer have conflicting interview';

/**
 * @description constant for maximum length of characters in the editor
 */
export const MAX_CHARACTER_LIMIT = 15000;

/**
 * regex for email and links validation for form fields of apply to a position
 */
export const EMAIL_REGEX = '^[a-z][a-z0-9-]*[.]?[a-z0-9-]+@[a-z0-9-]+[.]{1}[a-z]+';
export const GITHUB_REGEX = /^https:\/\/github.com\/[a-zA-Z0-9-_]+/;
export const LINKEDIN_REGEX = /^https:\/\/www.linkedin.com\/[a-zA-Z0-9-_]+/;
export const FILTER_NUMBER_REGEX = /[+-]?\d+(?:\.\d+)?/;

export const ALL_DATA_LIMIT = 0;

export const AUTH_CLIENT_ID = window.env?.REACT_APP_CLIENT_ID || process.env.REACT_APP_CLIENT_ID;

export const FARO_APP_NAME =
  window.env?.REACT_APP_FARO_APP_NAME || process.env.REACT_APP_FARO_APP_NAME;

export const FARO_APP_URL = window.env?.REACT_APP_FARO_URL || process.env.REACT_APP_FARO_URL;

export const LOGGED_IN_USER_INFO = 'loggedInUserInfo';
export const PATH_KEY = 'path';

export const ErrorTypes = {
  simple: 'simple',
  popOver: 'popOver',
  alert: 'alert',
  custom: 'custom',
};

export const TITLES = {
  HIRING_MOTION: 'Hiring Motion',
  PAGE_NOT_FOUND: '404 Page Not Found',
  HR_REPORTS: 'HR Reports',
  DASHBOARD: 'Dashboard',
  POSITIONS: 'Positions',
  APPLICATIONS_BETA: 'Applications (Beta)',
  APPLICATIONS: 'Applications',
  APPLICATION_OVERVIEW: 'Applications Overview',
  REFERRALS: 'Referrals',
  CALENDAR: 'Interviews',
  RESUME_SCREENING: 'Resume Screening',
  PRE_SCREENING: 'Pre Screening',
  TEAMS: 'Teams',
  REQUIREMENT: 'Requirement',
  MY_OPEN_POSITIONS: 'My Open Positions',
  USERS: 'Users',
  LEVELS: 'Levels',
  SKILLS: 'Skills',
  STREAMS: 'Streams',
  AGENCIES: 'Agencies',
  LOGIN: 'Login',
  INTERVIEWS: 'My Interviews',
  APPLICATIONS_DETAILS: 'Applications Details',
  SETTINGS: 'Settings',
  PANEL_REPORTS: 'Panel Reports',
  GENERAL_SETTINGS: 'General Settings',
  APPLICANT_DETAILS: 'Applicant Details',
  TABULAR_CALENDAR: 'Interviews',
};

export const SECTOR_1 =
  'ZopSmart Office, 2078, 24th Main Rd, Vanganahalli, 1st Sector, HSR Layout, Bengaluru, Karnataka 560102, India';

export const SECTOR_2 =
  'ZopSmart, 24th Main Rd, 22nd Cross Rd, Parangi Palaya, Sector 2, Bengaluru, Karnataka 560102';

export const NOIDA_OFFICE =
  'Sharkspace Coworking, A-135, 2nd Floor, A Block, Sector 63, Noida, Uttar Pradesh 201301';

export const ROLES = {
  ADMIN: 'ADMIN',
  HIRING_MANAGER: 'HIRING MANAGER',
  HR: 'HR',
  USER: 'USER',
  AGENCY: 'AGENCY',
};

export const ERROR_MESSAGE = 'Oops! Something went wrong please try again later.';

export const NOTICE_PERIOD_OPTIONS = [
  '15 days',
  '1 month',
  '45 days',
  '2 months',
  '3 months',
  'Serving',
  'Immediate',
];

export const ROUND_STATUS = [
  'SCHEDULED',
  'FEEDBACK PENDING',
  'RESULT PENDING',
  'PASS',
  'INTERVIEW CANCELED',
  'NEW',
  'PENDING INTERVIEW',
  'PENDING',
];

export const ROUND_RESPONSE = {
  'ROUND 1': '1st',
  'ROUND 2': '2nd',
  'ROUND 3': '3rd',
  'ROUND 4': '4th',
  'ROUND 5': '5th',
  'ROUND 6': '6th',
};

export const ROUND_NAME = [
  'PRE-SCREENING',
  'SCREENING ROUND',
  'ROUND 1',
  'ROUND 2',
  'ROUND 3',
  'ROUND 4',
  'ROUND 5',
  'ROUND 6',
];

export const COLORS_OPTIONS_MAP = new Map([
  ['NEW', '#52BBAB'],
  ['DROPPED', '#9C6C43'],
  ['OFFERED', '#14B35B'],
  ['PENDING INTERVIEW', '#52BBAB'],
  ['SCHEDULED', '#52BBAB'],
  ['PROCESSING', '#52BBAB'],
  ['INTERVIEW CANCELED', '#914A54'],
  ['REJECTED', '#B5021D'],
  ['JOINED', '#027033'],
  ['CLOSED', '#313636'],
  ['DUPLICATE', '#FF7903'],
  ['ON HOLD', '#DE7903'],
  ['PRE-SCREENING', '#675EE6'],
  ['ACTIVE', '#14B35B'],
  ['INACTIVE', '#B5021D'],
  ['OPEN', '#1ABA1D'],
  ['PASS', '#52BBAB'],
  ['FAIL', '#B5021D'],
  ['PRE SCHEDULED', '#52BBAB'],
  ['RESULT PENDING', '#52BBAB'],
]);
export const ACCEPT_TYPES =
  'application/pdf, image/*, .doc, .docx, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document';

export const DURATION_TIME = 60 * 60 * 1000;

export const drawerWidth = 19;

export const MOBILE_VIEW_WIDTH = 960;

export const HTML_HEADING_TAGS = ['<h1>', '<h2>', '<h3>', '<h4>'];

export const ApplicationContext = createContext();

export const AppContext = createContext();

export const AuthContext = createContext();

export const OtherContext = createContext();

export const RESCHEDULE_ERROR_MESSAGE =
  'The interview start time has already passed. Are you sure you want to update it?';

export const SOURCE_FILTER = [
  { id: 1, value: 'REFERRAL' },
  { id: 2, value: 'ZopSmart Website' },
  { id: 3, value: 'AGENCY' },
  { id: 4, value: 'ADMIN' },
  { id: 5, value: 'HR' },
];
